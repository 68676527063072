/* eslint-disable camelcase */
import { Ethnicity, Gender, Race } from '~/context/global/user/account';
import { VaccinationValue } from '~/common/vaccinations';

export interface SymptomsReport {
  symptoms?: {
    fever?: boolean;
    chillsornightsweats?: boolean;
    achesandpains?: boolean;
    fatigue?: boolean;
    cough?: boolean;
    lossofsmellortaste?: boolean;
    chesttightness?: boolean;
    gaspingforair?: boolean;
    sorethroat?: boolean;
    runnyorstuffynose?: boolean;
    sneezing?: boolean;
    stomachpainorcramp?: boolean;
    diarrhea?: boolean;
    vomiting?: boolean;
    lossofappetite?: boolean;
    nausea?: boolean;
    dizziness?: boolean;
    headaches?: boolean;
    rash?: boolean;
    other?: string;
  };
  otherdetails?: string;
  feverdetails?: {
    measured: boolean;
    temperature: number;
    unit: string;
  };
}

export interface MedicalDetailsReport {
  start_feeling_ill_date?: string;
  time_to_see_professional?: string;
  health_professional_details?: {
    hospital_overnight?: boolean;
    hospital_intense_care?: boolean;
    virtual_care?: boolean;
    other?: string;
    emergency_room?: boolean;
    instore_clinic?: boolean;
    urgent_care_center?: boolean;
    doctors_office_or_HMO?: boolean;
  };
  medical_test_details?: {
    covid_nose_swab?: boolean;
    influenza_nose_swab?: boolean;
    covid_spit_test?: boolean;
    at_home_covid_test?: boolean;
    covid_breath_test?: boolean;
    unsure?: boolean;
    other?: string;
  };
  diagnosis_details?: {
    covid?: boolean;
    influenza?: boolean;
    other?: string;
  };
  prescription_details?: {
    flu_antiviral?: boolean;
    steriod_inhaler?: boolean;
    antibiotic?: boolean;
    other?: string;
  };
}

export type TestResult = 'positive' | 'negative' | 'waiting';

export type TestsPerformed = {
  [key: string]: TestResult | '';
};

export interface BonusQuestionsReport {
  testedRecently?: boolean;
  typesOfTestPerformed?: TestsPerformed;
  reasonsForTesting?: string[];
  medicalTestFollowUp?: string;
  submissionId: number;
}

interface HealthReport {
  gender: Gender;
  race_id: Race;
  ethnicity_id: Ethnicity;
  user_submitted_race?: string;
  zipcode: string;
  birthdate: string;
  country_code: string;
  flu_season: string;
  covid_season: string;
  received_flu_vaccine?: VaccinationValue;
  received_covid_vaccine?: VaccinationValue;
  household_member_id?: number;
  email?: string;
  usertoken?: string;
}

export type WellReport = HealthReport;

export type HouseholdMemberWellSubmissionId = {
  submissionId: number;
  householdMemberId: number;
};

export interface WellSubmissionIds {
  userSubmissionId: number;
  householdMemberSubmissionIds: HouseholdMemberWellSubmissionId[];
}

export interface WellReportResponseData extends WellSubmissionIds {
  response: string;
  usertoken: string;
}

export interface UnwellReportResponseData {
  response: string;
  usertoken: string;
  userSubmissionId: number;
}

export interface UnwellReport
  extends HealthReport,
    SymptomsReport,
    MedicalDetailsReport {}

export enum HouseholdHealthStatus {
  WELL = 'WELL',
  UNWELL = 'UNWELL',
}
