import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectProps } from '@material-ui/core';
import { Select, SelectOptions } from './Select';
import { UseFormMethods, RegisterOptions, FieldError } from 'react-hook-form';
import { formFields, ethnicityIdFieldName } from '~/components/forms/constants';
import { Ethnicity } from '~/context/global/user/account';

interface FormContainingEthnicityField {
  [ethnicityIdFieldName]?: Ethnicity;
}

interface Props<Form> {
  form: UseFormMethods<Form>;
  validationSchema: Map<keyof Form, RegisterOptions>;
  getErrorMessage: (fieldName: keyof Form, errType?: string) => string;
  variant?: SelectProps['variant'];
  'data-cy'?: string;
}

export const EthnicitySelect = <Form extends FormContainingEthnicityField>({
  form,
  validationSchema,
  getErrorMessage,
  variant = 'outlined',
  'data-cy': dataCy,
}: Props<Form>): ReactElement => {
  const { t } = useTranslation();
  const ethnicityOptions: SelectOptions = t('FormField.ethnicityId.options', {
    returnObjects: true,
  });
  const displayError = form.errors?.ethnicityId as FieldError;
  const isHouseholdMember = dataCy?.includes('household-member');
  return (
    <>
      <Select
        showBlankDefault
        fullWidth
        id={formFields.ETHNICITY_ID}
        name={formFields.ETHNICITY_ID}
        variant={variant}
        data-cy={dataCy}
        options={ethnicityOptions}
        formControl={form.control}
        error={!!form.errors.ethnicityId}
        label={t(
          `FormField.ethnicityId.${
            isHouseholdMember ? 'householdMemberLabel' : 'label'
          }`
        )}
        validationRules={validationSchema.get(ethnicityIdFieldName)}
        errorMessage={getErrorMessage(ethnicityIdFieldName, displayError?.type)}
      />
    </>
  );
};
