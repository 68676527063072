import { useTranslation } from 'react-i18next';
import { Ethnicity, Race } from '~/context/global/user/account';
import { SelectOptions } from '../forms/fields/Select';
import { RegisterOptions, useForm, UseFormMethods } from 'react-hook-form';
import { ethnicityIdFieldName } from '~/components/forms/constants';

export interface HouseholdDemographicsForm {
  raceId: Race;
  userSubmittedRace?: string;
  ethnicityId: Ethnicity;
}

export type IndividualHouseholdDemographicsForm = UseFormMethods<
  HouseholdDemographicsForm
>;

interface UseHouseholdDemographicsForm {
  form: IndividualHouseholdDemographicsForm;
  validationSchema: Map<keyof HouseholdDemographicsForm, RegisterOptions>;
  getErrorMessage: (
    fieldName: keyof HouseholdDemographicsForm,
    errType?: string
  ) => string;
}

export const useHouseholdDemographicsForm = (): UseHouseholdDemographicsForm => {
  const { t } = useTranslation();
  const form = useForm<HouseholdDemographicsForm>({ mode: 'onChange' });
  const raceOptions: SelectOptions = t(`FormField.raceId.options`, {
    returnObjects: true,
  });
  const validationSchema = new Map<
    keyof HouseholdDemographicsForm,
    RegisterOptions
  >();

  validationSchema.set('raceId', {
    required: true,
    validate: {
      options: value => raceOptions.some(opt => opt.value === `${value}`),
    },
  });

  validationSchema.set('userSubmittedRace', {
    required: form.getValues().raceId?.toString() === Race.ANOTHER,
  });

  const ethnicityOptions: SelectOptions = t(`FormField.ethnicityId.options`, {
    returnObjects: true,
  });

  validationSchema.set(ethnicityIdFieldName, {
    required: true,
    validate: {
      options: value => ethnicityOptions.some(opt => opt.value === `${value}`),
    },
  });

  const getErrorMessage = (
    fieldName: keyof HouseholdDemographicsForm,
    errType?: string
  ) => {
    return t(`FormField.${fieldName}.errors.${errType}`, { defaultValue: '' });
  };

  return { form, validationSchema, getErrorMessage };
};
