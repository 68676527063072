import styled from 'styled-components';
import breakpoint from '~/common/breakpoint';
import { Grid, Typography } from '@material-ui/core';

export const PageContainer = styled(Grid).attrs({
  container: true,
  direction: 'column',
})`
  padding: 4em 2em;

  ${breakpoint.md`
    width: 50%;
    margin: auto;
    padding: 5em 0;
  `}
`;

export const Container = styled.div`
  padding: 1rem;
  padding-bottom: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 1rem;
`;

export const PageTitle = styled(Typography).attrs({
  align: 'center',
})`
  font-size: 1rem;
  font-weight: bold;
  ${breakpoint.md`
    font-size: 1.5rem;
  `}
`;

export const MemberName = styled(Typography).attrs({
  align: 'center',
  gutterBottom: false,
})`
  font-size: 1rem;
  ${breakpoint.md`
    font-size: 1.2rem;
  `}
`;

export const MemberDateOfBirth = styled(Typography).attrs({
  align: 'center',
})`
  font-size: 0.8rem;
  color: ${props => props.theme.palette.gray.mid};
`;

export const QuestionLabel = styled(Typography)`
  font-family: ${props => props.theme.fonts.mono};
  font-weight: 400;
  align-items: center;
  color: ${props => props.theme.palette.text.primary};
  font-size: 1.2rem;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  ${breakpoint.md`
    justify-content: center;
  `}
`;
