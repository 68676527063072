import React from 'react';
import { PageShell } from '~/components/BaseElements/pageShell';
import { CenteredContainer, LoadingIndicator } from './styles';

export const LoadingPage: React.FC = () => {
  return (
    <PageShell>
      <CenteredContainer>
        <LoadingIndicator />
      </CenteredContainer>
    </PageShell>
  );
};
