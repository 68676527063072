import styled from 'styled-components';
import { Grid, LinearProgress } from '@material-ui/core';

export const CenteredContainer = styled(Grid).attrs({
  container: true,
  justify: 'center',
  alignItems: 'center',
})`
  padding: 7rem 0;
`;

export const LoadingIndicator = styled(LinearProgress).attrs({
  color: 'primary',
})`
  width: 30%;
`;
