import { Account, HouseholdMember } from '~/context/global/user/account';
import {
  HealthStatus,
  HouseholdMemberHealthReport,
} from '~/context/global/reports/householdReport';

export const isMissingDemographics = (
  householdMember: HouseholdMember,
  memberReport: HouseholdMemberHealthReport
): boolean => {
  if (
    !householdMember.isActive ||
    memberReport.healthStatus === HealthStatus.SKIP
  ) {
    return false;
  }

  if (!householdMember.userRace) {
    return true;
  }

  if (!householdMember.userEthnicity) {
    return true;
  }

  return false;
};

export const isMainUserMissingDemographics = (
  userAccount: Account
): boolean => {
  if (!userAccount.userRace) {
    return true;
  }

  if (!userAccount.userEthnicity) {
    return true;
  }

  return false;
};
