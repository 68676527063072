import React from 'react';
import { useSelector } from '~/store';
import { PageProps } from 'gatsby';
import { SignInPage } from '~/components/SignIn';
import { LoadingPage } from '~/components/Loading';
import { useLanguageUrl } from '~/state/hooks/useLanguageUrl';

export const AuthGate: React.FC = props => {
  const baseUrl = useLanguageUrl();
  const { loggedIn } = useSelector(state => state.userAuth);

  if (loggedIn === undefined) {
    return <LoadingPage />;
  }

  if (!loggedIn) {
    window.history.replaceState(null, 'Sign In', baseUrl + 'signin');
    return <SignInPage />;
  }

  return <>{props.children}</>;
};

export const withAuthGate = (Component: React.FC) => (
  props: PageProps
): JSX.Element => (
  <AuthGate>
    <Component {...props} />
  </AuthGate>
);
