import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectProps } from '@material-ui/core';
import { Select, SelectOptions } from './Select';
import { UseFormMethods, RegisterOptions, FieldError } from 'react-hook-form';
import { BaseInput } from './BaseInput';
import { Race } from '~/context/global/user/account';

interface FormContainingRaceField {
  raceId?: Race;
  userSubmittedRace?: string;
}
interface Props<Form> {
  form: UseFormMethods<Form>;
  validationSchema: Map<keyof Form, RegisterOptions>;
  getErrorMessage: (fieldName: keyof Form, errType?: string) => string;
  variant?: SelectProps['variant'];
  'data-cy'?: string;
}

export const RaceSelect = <Form extends FormContainingRaceField>({
  form,
  validationSchema,
  getErrorMessage,
  variant = 'outlined',
  'data-cy': dataCy,
}: Props<Form>): ReactElement => {
  const { t } = useTranslation();

  const raceOptions: SelectOptions = t('FormField.raceId.options', {
    returnObjects: true,
  });

  const raceId = form.watch('raceId');
  const isAnotherRaceSelected =
    typeof raceId === 'string' ? /8/.test(raceId) : false;

  const raceError = form.errors?.raceId as FieldError;
  const userSubmittedRaceError = form.errors?.userSubmittedRace as FieldError;
  return (
    <>
      <Select
        data-cy={dataCy}
        showBlankDefault
        fullWidth
        id="raceId"
        name="raceId"
        variant={variant}
        options={raceOptions}
        formControl={form.control}
        error={!!form.errors.raceId}
        label={t('FormField.raceId.label')}
        validationRules={validationSchema.get('raceId')}
        errorMessage={getErrorMessage('raceId', raceError?.type)}
      />
      {isAnotherRaceSelected && (
        <BaseInput
          variant={variant}
          name="userSubmittedRace"
          data-test="userSubmittedRace_input"
          error={!!form.errors.userSubmittedRace}
          label={t(`FormField.userSubmittedRace.label`)}
          InputLabelProps={{ shrink: !!form.watch('userSubmittedRace') }}
          inputRef={form.register(validationSchema.get('userSubmittedRace'))}
          helperText={getErrorMessage(
            'userSubmittedRace',
            userSubmittedRaceError?.type
          )}
        />
      )}
    </>
  );
};
