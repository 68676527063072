import React from 'react';
import { Grid } from '@material-ui/core';
import { Account, HouseholdMember } from '~/context/global/user/account';
import { RaceSelect } from '~/components/forms/fields/RaceSelect';
import {
  IndividualHouseholdDemographicsForm,
  useHouseholdDemographicsForm,
} from './useHouseholdDemographicsForm';
import * as Styled from './styles';
import { EthnicitySelect } from '~/components/forms/fields/EthnicitySelect';
import { useTranslation } from 'react-i18next';

interface Props {
  householdMember?: HouseholdMember;
  mainUserAccount?: Account;
  formsByMember: Map<number | string, IndividualHouseholdDemographicsForm>;
}

export const HouseholdMemberDemographicsForm: React.FC<Props> = ({
  householdMember,
  formsByMember,
  mainUserAccount,
}) => {
  const { t } = useTranslation();
  const {
    form,
    validationSchema,
    getErrorMessage,
  } = useHouseholdDemographicsForm();

  if (householdMember) {
    formsByMember.set(householdMember.id, form);
  } else {
    formsByMember.set(mainUserAccount.email, form);
  }

  const user = householdMember ?? mainUserAccount;

  return (
    <>
      {mainUserAccount && (
        <Grid item>
          <Styled.QuestionLabel>
            {mainUserAccount.nickname} ({t(`HouseholdReport.you`)})
          </Styled.QuestionLabel>
        </Grid>
      )}

      {householdMember && (
        <Grid item>
          <Styled.QuestionLabel>{householdMember.name}</Styled.QuestionLabel>
          <Styled.MemberDateOfBirth>
            {householdMember.dateOfBirth}
          </Styled.MemberDateOfBirth>
        </Grid>
      )}

      <Grid container direction="column">
        {!user.userRace && (
          <Grid item>
            <RaceSelect
              form={form}
              validationSchema={validationSchema}
              getErrorMessage={getErrorMessage}
            />
          </Grid>
        )}
        {!user.userEthnicity && (
          <Grid item>
            <EthnicitySelect
              form={form}
              validationSchema={validationSchema}
              getErrorMessage={getErrorMessage}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
