/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@material-ui/core';
import { useNavigate } from '~/state/hooks/useNavigate';
import { useGlobalDispatch, useGlobalState } from '~/context/global';
import { HouseholdHealthStatus } from '~/context/global/reports/types';
import { HouseholdMemberDemographicsForm } from './HouseholdMemberDemographicsForm';
import {
  HouseholdDemographicsForm,
  IndividualHouseholdDemographicsForm,
} from './useHouseholdDemographicsForm';
import { HealthReportAction } from '~/context/global/reports/actions';
import {
  isMainUserMissingDemographics,
  isMissingDemographics,
} from './helpers';
import { isEmpty } from 'lodash';
import * as Styled from './styles';

interface Props {
  location?: Location & {
    state: {
      recaptchaToken?: string;
      householdHealthStatus?: HouseholdHealthStatus;
    };
  };
}

export const HouseholdDemographics: React.FC<Props> = ({ location }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const dispatch = useGlobalDispatch();
  const {
    healthReport,
    user: { account },
  } = useGlobalState();

  const formsByMember: Map<
    number | string,
    IndividualHouseholdDemographicsForm
  > = new Map();
  const recaptchaToken = location?.state?.recaptchaToken;
  const householdHealthStatus = location?.state?.householdHealthStatus;
  const isHouseholdWell = householdHealthStatus === HouseholdHealthStatus.WELL;
  const reportType = isHouseholdWell ? 'well' : 'unwell';
  const membersMissingDemographics = account.householdMembers?.filter(
    member => {
      const memberReport = healthReport.household.householdMembers[member.id];
      return isMissingDemographics(member, memberReport);
    }
  );

  const goToNextPage = () => {
    navigate('household-report-submission', {
      state: { recaptchaToken, householdHealthStatus },
    });
  };

  const saveMainUserDemographicsToStore = (
    formValues: HouseholdDemographicsForm
  ) => {
    dispatch({
      type: HealthReportAction.SET_HEAD_OF_HOUSEHOLD_HEALTH_REPORT,
      payload: {
        healthReport: {
          race_id: formValues.raceId,
          user_submitted_race: formValues.userSubmittedRace,
          ethnicity_id: formValues.ethnicityId,
        },
      },
    });
  };

  const saveHouseholdDemographicsToStore = (
    formValues: HouseholdDemographicsForm,
    memberID: number
  ) => {
    dispatch({
      type: HealthReportAction.SET_HOUSEHOLD_MEMBER_HEALTH_REPORT,
      payload: {
        memberID,
        memberReport: {
          race_id: formValues.raceId,
          user_submitted_race: formValues.userSubmittedRace,
          ethnicity_id: formValues.ethnicityId,
        },
      },
    });
  };

  const submitForms = async () => {
    let allFormsValid = true;

    for (const [, form] of formsByMember) {
      const valid = await form.trigger();
      if (!valid) {
        allFormsValid = false;
      }
    }

    if (!allFormsValid) {
      return;
    }

    for (const [key, form] of formsByMember) {
      if (key === account.email) {
        saveMainUserDemographicsToStore(form.getValues());
      } else {
        saveHouseholdDemographicsToStore(form.getValues(), Number(key));
      }
    }

    goToNextPage();
  };

  useEffect(function checkFlowValidity() {
    if (!recaptchaToken) {
      navigate('', { replace: true });
    }
  }, []);

  useEffect(
    function skipThisStep() {
      const nobodyIsMissingDemographics =
        isEmpty(membersMissingDemographics) &&
        !isMainUserMissingDemographics(account);
      if (nobodyIsMissingDemographics) {
        goToNextPage();
      }
    },
    [membersMissingDemographics, isHouseholdWell, account]
  );

  return (
    <Styled.PageContainer>
      <Styled.Container>
        <Styled.PageTitle>
          {t(`HouseholdVaccination.pageTitle.${reportType}`)}
        </Styled.PageTitle>
      </Styled.Container>
      <Styled.Container>
        <Grid container direction="column">
          {isMainUserMissingDemographics(account) && (
            <HouseholdMemberDemographicsForm
              mainUserAccount={account}
              formsByMember={formsByMember}
            />
          )}
          {membersMissingDemographics.map(member => (
            <HouseholdMemberDemographicsForm
              householdMember={member}
              formsByMember={formsByMember}
            />
          ))}
        </Grid>
      </Styled.Container>
      <Styled.BottomContainer>
        <Button
          color="primary"
          variant="contained"
          onClick={submitForms}
          type="submit"
        >
          {t(`HouseholdReport.button.next`)}
        </Button>
      </Styled.BottomContainer>
    </Styled.PageContainer>
  );
};
